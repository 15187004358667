import { useState, useEffect, useRef } from 'react';
import { Box, Container, Grid, Typography, Button, Input, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import constants from 'common/constants';
import { query, collection, orderBy, startAfter, limit, getDocs, where, doc, getDoc } from "firebase/firestore";
import { getAnalytics, logEvent } from 'firebase/analytics'
import colors from "theme/colors";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import { Functions } from 'common/functions';
import TiktokPixel from 'utils/tiktok_pixel';
import VerticalCard from '../../components/product-vertical-card';
import HorizontalCard from '../../components/product-horizontal-card';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from 'services/firebase/firebaseConfig';

const client = algoliasearch(constants.algolia.appId, constants.algolia.searchKey)
const desc_index = client.initIndex('firebase_products_createdAt_desc');
const productsStores_index = client.initIndex('firebase_products_stores');

var last_search = "";

const Products = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const [carousel, setCarousel] = useState(null);
    const [related, setRelated] = useState(null);
    const [loadingRelated, setLoadingRelated] = useState(true);
    const [products, setProducts] = useState([]);
    const [skipBestDealsProducts, setSkipBestDealsProducts] = useState(null);
    const [skipCategoryProducts, setSkipCategoryProducts] = useState(0);
    const [skipRecentProducts, setSkipRecentProducts] = useState(0);
    const [skipCarouselProducts, setSkipCarouselProducts] = useState(0);
    const limitProducts = 15;
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [allProductsDisplayed, setAllProductsDisplayed] = useState(false);
    const [persist_scroll, set_persist_scroll] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [init, setInit] = useState(false);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubcategory, setSelectedSubcategory] = useState("empty");
    const [listId, setListId] = useState("");

    useEffect(() => {
        const analytics = getAnalytics();
		logEvent(analytics, 'screen_view', {
            firebase_screen: "ListaProductos_Web", 
            firebase_screen_class: "ListaProductos_Web"
		});
        TiktokPixel.pageView();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let storageItem = sessionStorage.getItem("products_persist")
        let products_persist = JSON.parse(storageItem)
        if(products_persist){
            // console.log("productos: ", products_persist.products)
            set_persist_scroll(products_persist.scrollY)
            setSkipBestDealsProducts(new Date(products_persist.skipBestDealsProducts))
            setSkipCategoryProducts(products_persist.skipCategoryProducts)
            setSkipRecentProducts(products_persist.skipRecentProducts)
            setSkipCarouselProducts(products_persist.skipCarouselProducts)
            setProducts(products_persist.products)
            setCarousel(products_persist.carousel)
            setRelated(products_persist.related)
            setLoadingRelated(products_persist.loadingRelated)
            setLoading(products_persist.loading)
            setLoadingMore(products_persist.loadingMore)
            setAllProductsDisplayed(products_persist.allProductsDisplayed)
            setSearchText(products_persist.searchText)
            setSelectedSubcategory(products_persist.selectedSubcategory)
            setSubCategories(products_persist.subCategories)
            sessionStorage.removeItem("products_persist")
        }else{
            window.scrollTo(0, 0)
            if(urlParams.get("category")){
                logEvent(analytics, 'view_item_list', {
                    item_list_id: "category_products_Web",
                    item_list_name: "Productos por categoria_Web"
                });
                getCategoryProducts()
                getSubCategories()
                let category = urlParams.get("category")
                setListId(`list_category_${category}`)
            }else if(urlParams.get("filter")){
                let filter = urlParams.get("filter")
                if(filter == "offers"){
                    logEvent(analytics, 'view_item_list', {
                        item_list_id: "offers_Web",
                        item_list_name: "Promociones_Web"
                    });
                    getBestDeals()
                    setListId("list_offers")
                }else if(filter == "recent"){
                    logEvent(analytics, 'view_item_list', {
                        item_list_id: "recent_products_Web",
                        item_list_name: "Productos recientes_Web"
                    });
                    getRecentProducts()
                    setListId("list_recent")
                }
            }else if(urlParams.get("carousel")){
                let uid = urlParams.get("carousel")
                logEvent(analytics, 'view_item_list', {
                    item_list_id: "carousel_products_Web",
                    item_list_name: "Carrusel_Web"
                });
                getCarousel(uid)
                setListId(`list_carousel_${uid}`)
            }else{
                setLoading(false)
            }
        }
        setInit(true)
	},[])

    useEffect(()=>{
        if(init == true){
            if(persist_scroll && products.length > 0){
                window.scrollTo(0, persist_scroll)
                set_persist_scroll(0)
            }
        }
    },[products, persist_scroll, init])

    const getCarousel = async (uid) => {
        try {
            const docRefCarousel = doc(db, constants.mode + "carousels", uid);
			const docSnapCarousel = await getDoc(docRefCarousel);
            if(docSnapCarousel.data()){
                let carouselAux = {
                    ...docSnapCarousel.data(),
                    uid: docSnapCarousel.id
                }
                if(carouselAux.related && carouselAux.related != ""){
                    getRelated(carouselAux.related)
                }else{
                    setLoadingRelated(false)
                }
                if(carouselAux.type == "filters"){
                    setCarousel(carouselAux)
                    await getCarouselProducts(carouselAux)
                }else{
                    setCarousel(carouselAux)
                    setProducts(carouselAux.products)
                    setAllProductsDisplayed(true)
                    setLoading(false)
                }
            }else{
				setLoading(false)
			}
        }catch(error){
            setLoading(false)
        }
    }

    const getCarouselProducts = async (carouselAux) => {
        setLoadingMore(true)
        try {
            let filters = ``
            Object.entries(carouselAux.categoryFilter).forEach(([key, value], index) => {
                filters = filters + `cropCategories.${key}:"${value}" AND `
            });
            filters = filters + `ownerType:"store"`
            productsStores_index.search("", {
                filters: filters,
                page: skipCarouselProducts,
                ruleContexts: "client"
            }).then(({ hits }) => {
                if(hits.length < 20){
                    setAllProductsDisplayed(true)
                }
                setSkipCarouselProducts(skipCarouselProducts + 1)
                let auxProducts = []
                hits.forEach((doc) => {
                    auxProducts.push({
                        ...doc,
                        uid: doc.objectID,
                        price: parseFloat(doc.price)
                    })
                });
                setLoadingMore(false)
                setLoading(false)
                setProducts([])
                setProducts([...products, ...Functions.shuffleArray(auxProducts)])
            })
        }catch(error){
            setLoading(false)
            setLoadingMore(false)
        }
    }

    const getRelated = async (relatedUid) => {
        try{
            const docRef = doc(db, constants.mode + "carousels", relatedUid);
            const docSnap = await getDoc(docRef);
            if(docSnap.data()){
                let carouselResponse = {
                    ...docSnap.data(),
                    uid: docSnap.id
                }
                if(carouselResponse.type == "filters"){
                    let carouselAux = await getRelatedProducts(carouselResponse)
                    setRelated(carouselAux)
                    setLoadingRelated(false)
                }else{
                    setRelated(carouselResponse)
                    setLoadingRelated(false)
                }
            }else{
                setLoadingRelated(false)
            }
        }catch(error){
            setLoadingRelated(false)
        }
    }

    const getRelatedProducts = async (carouselAux) => {
        try {
            let filters = ``
            Object.entries(carouselAux.categoryFilter).forEach(([key, value], index) => {
                filters = filters + `cropCategories.${key}:"${value}" AND `
            });
            filters = filters + `ownerType:"store"`
            let response = await productsStores_index.search("", {
                filters: filters,
                ruleContexts: "client"
            })
            var products = [...response.hits.slice(0,15)]
            return carouselAux = {
                ...carouselAux,
                products
            }
        }catch(error){
            console.log("error related carousel: ", error)
        }
    }

    const getSubCategories = async () => {
        try{
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let category = urlParams.get("category")
            const docRef = doc(db, constants.mode + "crop_categories", category)
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                let subcategories = Object.values(docSnap.data().sub)
                setSubCategories(subcategories)
                // console.log("Document data:", subcategories);
            }
        }catch(error){
            console.log("subcategorías: ", error)
        }
    }

    const getCategoryProducts = async () => {
        setLoadingMore(true)
        try {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let category = urlParams.get("category")
            let filters = ``
            if(selectedSubcategory != "empty"){
                filters = `cropCategories.lvl0:"${category}" AND cropCategories.lvl1:"${category + " > " + selectedSubcategory}" AND ownerType:"store"`
            }else{
                filters = `cropCategories.lvl0:"${category}" AND ownerType:"store"`
            }
            productsStores_index.search(searchText, {
                filters: filters,
                page: skipCategoryProducts,
                ruleContexts: "client"
            }).then(({ hits }) => {
                // console.log("Product hits: ", hits)
                if(hits.length < 20){
                    setAllProductsDisplayed(true)
                }
                setSkipCategoryProducts(skipCategoryProducts + 1)
                let auxProducts = []
                hits.forEach((doc) => {
                    auxProducts.push({
                        ...doc,
                        uid: doc.objectID,
                        price: parseFloat(doc.price)
                    })
                });
                setLoadingMore(false)
                setLoading(false)
                setProducts([])
                setProducts([...products, ...Functions.shuffleArray(auxProducts)])
            });
        } catch (error) {
            setLoading(false)
            setLoadingMore(false)
            console.log("productos con categoría: ", error)
        }
    }

    const getBestDeals = async () => {
        setLoadingMore(true)
		try {
            let docSnap = null
            if(skipBestDealsProducts){
                const collRef = collection(db, constants.mode + "products")
                const docsRef = query(collRef, orderBy("promotion", "desc"), where("promotion", "!=", ""), where("ownerType", "==", "store"), where("disabled","==",false), orderBy("stripe_payment", "desc"), orderBy("createdAt", "desc"), startAfter(skipBestDealsProducts), limit(limitProducts))
                docSnap = await getDocs(docsRef);
            }else{
                const collRef = collection(db, constants.mode + "products")
                const docsRef = query(collRef, orderBy("promotion", "desc"), where("promotion", "!=", ""), where("ownerType", "==", "store"), where("disabled","==",false), orderBy("stripe_payment", "desc"), orderBy("createdAt", "desc"), limit(limitProducts))
                docSnap = await getDocs(docsRef);
            }
            if(docSnap.docs.length > 0){
                if(docSnap.docs.length < 15){
                    setAllProductsDisplayed(true)
                }
                setSkipBestDealsProducts(docSnap.docs[docSnap.docs.length-1])
                var newProducts = []
                await docSnap.forEach((doc) => {
                    const product = {
                        uid: doc.id,
                        ...doc.data(),
                        price: parseFloat(doc.data().price),
                    }
                    newProducts.push(product)
                });
                newProducts = Functions.shuffleArray(newProducts)
                setProducts([...products, ...newProducts])
                setLoading(false)
                setLoadingMore(false)
            }else{
                setAllProductsDisplayed(true)
                setLoading(false)
                setLoadingMore(false)
            }
        } catch (error) {
            setLoading(false)
            setLoadingMore(false)
            console.log("productos: ", error)
        }
	} 

    const getRecentProducts = async () => {
        try{
			let filters = `NOT store.label:"Treembo Store" AND ownerType:"store"`
			desc_index.search("", {
				filters: filters,
                page: skipRecentProducts,
                ruleContexts: "client"
			}).then(({ hits }) => {
				// console.log("Product hits: ", hits)
                if(hits.length < 15){
                    setAllProductsDisplayed(true)
                }
                setSkipRecentProducts(skipRecentProducts + 1)
				let auxProducts = []
				hits.forEach((doc) => {
					auxProducts.push({
						...doc,
						uid: doc.objectID,
                        price: parseFloat(doc.price),
					})
				});
                auxProducts = Functions.shuffleArray(auxProducts)
				setLoadingMore(false)
                setLoading(false)
				setProducts([])
				setProducts([...products, ...auxProducts])
			});
		}catch(error){
			console.log("RECENT PRODUCTS ERROR: ", error)
			setLoadingMore(false)
            setLoading(false)
		}
    }

    const moreProducts = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(loadingMore == false){
            if(urlParams.get("filter")){
                let filter = urlParams.get("filter")
                if(filter == "offers"){
                    getBestDeals()
                }else if(filter == "recent"){
                    getRecentProducts()
                }
            } else if(urlParams.get("category")){
                getCategoryProducts()
            } else if(urlParams.get("carousel")){
                getCarouselProducts(carousel)
            }
        }
    }

    const handleSubmit = (e) => {
        setSearchText(e.target.value)
    }

    useEffect(() => {
        if(last_search != searchText){
            if(searchText.length > 2 || searchText.length == 0){
                if(searchText.length > 2){
                    const analytics = getAnalytics();
                    logEvent(analytics, 'search', {
                        search_term: searchText
                    });
                }
                setLoadingMore(false)
                setLoading(true)
                setAllProductsDisplayed(false)
                setSkipCategoryProducts(0)
                setProducts([])
                set_persist_scroll(0)
            }
        }
    }, [searchText])

    useEffect(() => {
        if(persist_scroll == 0 && last_search != searchText && products.length == 0){
            last_search = searchText
            getCategoryProducts()
        }
    },[products])

    const _handleSubcategory = (event) => {
        setLoadingMore(false)
        setLoading(true)
        setAllProductsDisplayed(false)
        setSkipCategoryProducts(0)
        setProducts([])
        set_persist_scroll(0)
        setSelectedSubcategory(event.target.value)
    }

    useEffect(() => {
        if(init == true){
            if(persist_scroll == 0 && products.length == 0){
                getCategoryProducts()
            }
        }
    }, [selectedSubcategory])

    const openProduct = async (product) => {
        let products_persist = {
            products,
            carousel,
            related,
            loadingRelated,
            skipBestDealsProducts,
            skipCategoryProducts,
            skipRecentProducts,
            skipCarouselProducts,
            loading,
            loadingMore,
            allProductsDisplayed,
            scrollY: window.scrollY,
            searchText,
            subCategories,
            selectedSubcategory
        }
        sessionStorage.setItem("products_persist", JSON.stringify(products_persist))
        let uid = ""
        if(product.objectID){
            uid = product.objectID
        }else if(product.uid){
            uid = product.uid
        }else{
            uid = product.id
        }
        navigate(`/product/${uid}`)
        try{
            let click = {
                productId: uid,
                scopeType: listId,
                origin: "organic_web_open"
            }
            const addProductClick = httpsCallable(functions, 'products-addProductClick')
            const result = await addProductClick(click);
            console.log(result.data.message);
        }catch(error){
            console.error('Error logging action:', error.message);
        }
    }

    const _renderSectionTitle = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.get("filter")){
            let filter = urlParams.get("filter")
            if(filter == "offers"){
                return  "Promociones"
            } else if(filter == "recent"){
                return  "Productos recientes"
            } else{
                return "Tree-Commerce"
            }
        } else if(urlParams.get("category")){
            let category = urlParams.get("category")
            return  category.charAt(0).toUpperCase() + category.slice(1)
        } else if(urlParams.get("carousel")){
            if(carousel != null){
                return carousel.name
            }else{
                return "Tree-Commerce"
            }
        }
    }

    const _renderSearcher = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.get("category")){
            let category = urlParams.get("category")
            return  <Input
                        placeholder={"Buscar para " + category.charAt(0).toUpperCase() + category.slice(1)}
                        sx={{ width: "100%", height: "32px", marginTop: "16px", paddingLeft: "16px", paddingRight: "16px", border: "1px solid " + colors.medium_light_gray, borderRadius: "16px" }}
                        disableUnderline={true}
                        onChange={handleSubmit}
                        value={searchText}
                    />
        }
    }

    const _renderProductCard = (product, i, carouseluid) => {
        let type = `carousel_${carouseluid}`
		return	<VerticalCard
                    product={product}
                    scopeType={type}
                />
	}

    if(loading){
        return  <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Typography>
                        Cargando...
                    </Typography>
                </Box>
    }else{
        return (
            <Container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", paddingBottom: "40px", alignItems: "center"}}>
                <Box sx={{ display: "flex", flexDirection: "row", width: "100%", top: "8rem", backgroundColor: "white", paddingTop: "16px", alignItems: "center" }}>
                    <ChevronLeft sx={{ fontSize: 36, marginRight: "16px" }} color="primary" onClick={()=>{navigate(-1)}}/>
                    <Typography variant='title4'>
                        {_renderSectionTitle()}
                    </Typography>
                </Box>
                {_renderSearcher()}
                {subCategories.length > 0 ?
                    <Box sx={{ display: "flex", flexDirection: "row", minWidth: "100%", marginTop: "16px", justifyContent: "flex-end" }}>
                        <FormControl>
                            <InputLabel id="select-subcategory">Subcategoría</InputLabel>
                            <Select
                                labelId="select-subcategory"
                                id="select"
                                value={selectedSubcategory}
                                label="Subcategoría"
                                onChange={_handleSubcategory}
                                sx={{ height: "32px" }}
                                MenuProps={{ style: { zIndex: 11000 }}}
                            >
                                <MenuItem value={"empty"}>Seleccionar</MenuItem>
                                {subCategories.map((subcategory, index) => {
                                    return  <MenuItem value={subcategory.id}>{subcategory.es}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                :<></>}
                {products && products.length > 0 ?
                        <Grid container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "center", marginTop: "16px", backgroundColor: "white" }}>
                            {products.map((product, index)=> {
                                if(product){
                                    return  <div key={index}>
                                                <HorizontalCard
                                                    product={product}
                                                    openProduct={()=> openProduct(product)}
                                                    scopeType={listId}
                                                />
                                                <div style={{ backgroundColor: colors.light_gray, height: "1px", width: "100%" }}/>
                                            </div>
                                }else{
                                    return  <Box>
                                                <Typography>Producto no disponible</Typography>
                                            </Box>
                                }
                            })}
                            {loadingMore == true ? 
                                <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <Typography>
                                        Cargando...
                                    </Typography>
                                </Box>
                            :
                                !allProductsDisplayed ?
                                    <Button sx={{ marginTop: "8px" }} onClick={()=>moreProducts()}>
                                        <Typography variant='title8' sx={{ color: colors.primary_green }}>Ver más...</Typography>
                                    </Button>
                                :null
                            }
                            {!loadingMore && allProductsDisplayed && !loadingRelated && related != null && related.products && related.products.length > 0 ?
                                <Grid container sx={{display: "flex", flexDirection: "column", marginTop: "12px"}}>
                                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                                        <Typography variant={'title8'} color="twilight">{related.name}</Typography>
                                    </Box>
                                    <Grid container sx={{ display: 'flex', flexDirection: "column", overflow: "auto" }}>
                                        <Grid sx={{ display: 'flex', flexDirection: "row", marginTop: "8px"}}>
                                            {related.products.map((product, index)=>{
                                                return _renderProductCard(product, index, related.uid)
                                            })}
                                        </Grid>
                                    </Grid> 
                                </Grid>
                            :<></>}
                        </Grid>
                    :
                        <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Typography>
                                No hay productos
                            </Typography>
                        </Box>
                }
            </Container>
	    )
    }
}

export default Products;