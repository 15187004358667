import React, { useReducer, createContext } from "react";

export const AnalyticsContext = createContext();

const initialState = {
	productsViews: [],
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_PRODUCT_VIEW':
			return {
				...state,
				productsViews: [...state.productsViews, action.payload]
			};
		case 'CLEAN_PRODUCT_VIEW':
			return {
				...state,
				productsViews: []
			};
		default:
			return {
				state
			};
	}
};

export const AnalyticsProvider = props => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<AnalyticsContext.Provider value={[state, dispatch]}>
			{props.children}
		</AnalyticsContext.Provider>
	);
};