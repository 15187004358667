import colors from 'theme/colors';

let sx = {
	productCard: {
		display: "flex", 
		flexDirection: "column", 
		backgroundColor: "white", 
		width: "112px",
		//justifyContent: "center", 
		alignItems: "center", 
		cursor: "pointer",
		marginRight: "8px"
	},
    productImageContainer: { 
		display: "block", 
		flexDirection: "row", 
		justifyContent: "center", 
		alignItems: "center",
		marginTop: "8px", 
		cursor: "pointer", 
		width: "112px", 
		height: "112px", 
		borderRadius: "8px", 
		overflow: "hidden",
		position: "relative",
		marginRight: "12px",
	},
	productInfoContainer: { 
		display: "flex", 
		flexDirection: "column", 
		marginTop: "4px", 
		marginBottom: "8px", 
		width: "112px", 
		marginRight: "12px", 
		justifyContent: "space-between", 
		cursor: "pointer" 
	},
    headText: { 
        display: "flex", 
        flexDirection: "column" 
    },
    productName: { 
		display: '-webkit-box', 
		overflow: 'hidden', 
		WebkitBoxOrient: 'vertical', 
		WebkitLineClamp: 2
	},
	productDesc: { 
		display: '-webkit-box', 
		overflow: 'hidden', 
		WebkitBoxOrient: 'vertical', 
		WebkitLineClamp: 1
	},
	productDesc2Lines: { 
		display: '-webkit-box', 
		overflow: 'hidden', 
		WebkitBoxOrient: 'vertical', 
		WebkitLineClamp: 2
	},
    previousPriceContainer: { 
        display: "flex", 
        flexDirection: "row", 
        alignItems: "flex-end" 
    },
    previousPriceText: { 
        color: colors.medium_gray, 
        textDecorationLine: "line-through" 
    },
    priceContainer: { 
        display: "flex", 
        flexDirection: "row", 
        alignItems: "center"
    },
    priceBox: {
        display: "flex", 
        flexDirection: "row", 
        alignItems: "center"
    },
    priceText: {
        color: colors.primary_green, 
        fontSize: "10px"
    },
    insigniasContainer: {
        display: "flex", 
        flexDirection: "row", 
        flexWrap: "wrap"
    },
    
}

export default sx;