import colors from 'theme/colors';

let sx = {
	mainContainer: {
		display: "flex",
		paddingTop: "32px",
		paddingBottom: "32px",
		justifyContent: "center",
		alignItems: "center"
	},
	mainContainerMobile: {
		display: "flex",
		paddingTop: "16px",
		paddingBottom: "16px",
		justifyContent: "center",
		alignItems: "center",
	},
	mainGrid: {
		
	},
	carouselGrid: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center"
	},
	sectionGrid: {
		display: "flex",
		flexDirection: "column",
		//justifyContent: "center",
		//alignItems: "center",
		marginTop: "12px",
		//backgroundColor: "blue"
	},
	titleRow: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		//backgroundColor: "red"
	},
	moreRow: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "right",
		cursor: "pointer",
		width: "90px"
	},
	storeCard: { 
		display: "flex", 
		flexDirection: "column", 
		backgroundColor: "white", 
		// height: "116px", 
		width: "90px", 
		marginTop: "4px", 
		marginBottom: "4px", 
		marginRight: "4px",
		paddingLeft: "8px",
		paddingRight: "8px",
		// justifyContent: "center", 
		alignItems: "center", 
		cursor: "pointer" 
	},
	storeImageContainer: { 
		display: "flex", 
		flexDirection: "row", 
		justifyContent: "center", 
		alignItems: "center", 
		backgroundColor: "white", 
		cursor: "pointer" 
	},
	storeImage: { 
		borderRadius: "40px", 
		objectFit: "contain"
	},
	categoryCard: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: "86px",
		width: "86px",
		marginTop: "4px",
		marginRight: "4px",
		borderRadius: "12px",
		backgroundColor: "white",
		cursor: "pointer",
		border: "1px solid " + colors.light_gray
	},
	categoryText: {
		textAlign: "center",
		marginTop: "4px"
	},
	productCard: {
		display: "flex", 
		flexDirection: "column", 
		backgroundColor: "white", 
		width: "112px",
		justifyContent: "center", 
		alignItems: "center", 
		cursor: "pointer"
	},
	productImageContainer: { 
		display: "block", 
		flexDirection: "row", 
		justifyContent: "center", 
		alignItems: "center",
		marginTop: "8px", 
		cursor: "pointer", 
		width: "112px", 
		height: "112px", 
		borderRadius: "8px", 
		overflow: "hidden",
		position: "relative",
		marginRight: "12px",
	},
	productInfoContainer: { 
		display: "flex", 
		flexDirection: "column", 
		marginTop: "4px", 
		marginBottom: "8px", 
		width: "112px", 
		// paddingLeft: "8px", 
		marginRight: "12px", 
		justifyContent: "space-between", 
		cursor: "pointer" 
	},
	promoPill: {
		display: "flex", 
		position: "absolute", 
		bottom: "4px", 
		right: "4px", 
		backgroundColor: "sunset", 
		marginLeft: "28px", 
		height: "22px", 
		borderRadius: "12px", 
		paddingLeft: "8px", 
		paddingRight: "8px", 
		alignItems: "center"
	},
	promoText: { 
		display: '-webkit-box', 
		overflow: 'hidden', 
		WebkitBoxOrient: 'vertical', 
		WebkitLineClamp: 1, 
		color: "white" 
	},
	productName: { 
		display: '-webkit-box', 
		overflow: 'hidden', 
		WebkitBoxOrient: 'vertical', 
		WebkitLineClamp: 2
	},
	productDesc: { 
		display: '-webkit-box', 
		overflow: 'hidden', 
		WebkitBoxOrient: 'vertical', 
		WebkitLineClamp: 1
	},
	productDesc2Lines: { 
		display: '-webkit-box', 
		overflow: 'hidden', 
		WebkitBoxOrient: 'vertical', 
		WebkitLineClamp: 2
	},
	storeName: {
		display: '-webkit-box', 
		overflow: 'hidden', 
		WebkitBoxOrient: 'vertical', 
		WebkitLineClamp: 2,
		textAlign: "center"
	}
}

export default sx;