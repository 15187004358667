import * as React from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import sx from './styles'
import imgSuffix from 'common/image-suffix';
import percentageDifference from 'utils/percentageDifference';
import Discount from "assets/svgs/product_insignia/discount.svg"
import FreeShipping from "assets/svgs/product_insignia/free_shipping.svg"
import MSI from "assets/svgs/product_insignia/msi.svg"
import Offer from "assets/svgs/product_insignia/offer.svg"
import { AnalyticsContext } from 'contexts/analyticsContext';

export default function SearchCard(props) {
    const product = props.product;
    const [state, dispatch] = React.useContext(AnalyticsContext);
    const [isInView, setIsInView] = React.useState(false);

    React.useEffect(()=>{

    },[])

    const onChange = (isVisible) => {
        if(isInView == false && isVisible == true){
            setIsInView(isVisible)
        }
    }

    React.useEffect(()=>{
        if(isInView == true){
            let uid = ""
            if(product.objectID){
                uid = product.objectID
            }else if(product.uid){
                uid = product.uid
            }else{
                uid = product.id
            }
            let view = {
                productId: uid,
                scopeType: "search",
                origin: "organic_web_open"
            }
            dispatch({
                type: 'SET_PRODUCT_VIEW',
                payload: view
            });
        }
    },[isInView])

    return (
        <ReactVisibilitySensor onChange={onChange}>
            <div onClick={()=>props.openProduct()} key={product.objectID} style={sx.container}>
                <div style={sx.imageContainer}>
                    {product.images ?
                        <img
                            alt="img"
                            src={product.images[0] + imgSuffix.md}
                            style={sx.image}
                        /> 
                    : (product.legacy_images ? 
                        <img
                            alt="img"
                            src={product.legacy_images[0] + "480x480"}
                            style={sx.image}
                        /> 
                    : "")}
                </div>
                <div style={sx.infoContainer}>
                    <Typography variant="title8" sx={sx.nameText}>
                        {product.name}
                    </Typography>
                    <Typography variant="body2" sx={sx.descriptionText}>
                        {product.description}
                    </Typography>
                    {product.hasDiscount && product.hasDiscount == true ?
                        <Box item sx={sx.previousPriceContainer}>
                            <Typography variant="caption1" sx={sx.previousPriceText}>${product.previousPrice ? product.previousPrice.toLocaleString("en-US") : 0}&nbsp;</Typography>
                        </Box> 
                    :null}
                    <Box sx={sx.priceContainer}>
                        {product.price && product.price != 0 && product.price != "" && product.price != null ?
                            <Typography variant="title7">${product.price.toLocaleString("en-US")}</Typography>
                        :null}
                        {product.discountPercentageVisible && product.hasDiscount && product.hasDiscount == true && product.previousPrice && parseFloat(product.previousPrice) > 0 ? 
                            <Box style={sx.percentageContainer}>
                                <Typography variant='body3' style={sx.percentageText}>
                                    %{percentageDifference(product.previousPrice, product.price)} OFF
                                </Typography>
                                <Box sx={sx.discount}>
                                    <img 
                                        src={Discount} 
                                        height={18} 
                                        width={18} 
                                        alt=''
                                    />
                                </Box>
                            </Box>
                        :<></>}
                    </Box>
                    <Box style={sx.insigniasContainer}>
                        {product.free_shipping ? <img 
                            src={FreeShipping} 
                            height={18} 
                            width={18} 
                            alt=''
                            style={sx.insignia}
                        /> : <></>}
                        {product.msi ? <img 
                            src={MSI} 
                            height={18} 
                            width={18} 
                            alt=''
                            style={sx.insignia}
                        /> : <></>}
                        {product.promotion ? <img 
                            src={Offer} 
                            height={18} 
                            width={18} 
                            alt=''
                            style={sx.insignia}
                        /> : <></>}
                    </Box>
                </div>
            </div>
        </ReactVisibilitySensor>
    );
}