import * as React from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import sx from './styles'
import imgSuffix from 'common/image-suffix';
import { AnalyticsContext } from 'contexts/analyticsContext';


export default function HorizontalCard(props) {
    const product = props.product;
    const scopeType = props.scopeType;
    const [state, dispatch] = React.useContext(AnalyticsContext);
    const [isInView, setIsInView] = React.useState(false);

    React.useEffect(()=>{

    },[])

    const onChange = (isVisible) => {
        if(isInView == false && isVisible == true){
            setIsInView(isVisible)
        }
    }

    React.useEffect(()=>{
        if(isInView == true){
            let uid = ""
            if(product.objectID){
                uid = product.objectID
            }else if(product.uid){
                uid = product.uid
            }else{
                uid = product.id
            }
            let view = {
                productId: uid,
                scopeType: scopeType,
                origin: "organic_web_open"
            }
            dispatch({
                type: 'SET_PRODUCT_VIEW',
                payload: view
            });
        }
    },[isInView])

    return (
        <ReactVisibilitySensor onChange={onChange}>
            <Grid container onClick={()=>props.openProduct()} sx={sx.container}>
                <Grid item xs={3} sm={2} md={2} sx={sx.imageContainer}>
                    {product.images ?
                        <img
                            alt="img"
                            src={product.images[0] + imgSuffix.md}
                            style={sx.image}
                        /> 
                    : (product.legacy_images ? 
                        <img
                            alt="img"
                            src={product.legacy_images[0] + "480x480"}
                            style={sx.image}
                        /> 
                    : "")}
                </Grid>
                <Grid item xs={8} sm={9} md={9} sx={sx.infoContainer}> 
                    <Typography variant="title8" sx={sx.nameText}>{product.name}</Typography>
                    <Typography variant="body2" sx={sx.descriptionText}>{product.description}</Typography>
                    {product.hasDiscount && product.hasDiscount == true ?
                        <Box item sx={sx.previousPriceContainer}>
                            <Typography variant="caption1" sx={sx.previousPriceText}>${product.previousPrice ? product.previousPrice.toLocaleString("en-US") : 0}&nbsp;</Typography>
                        </Box> 
                    :null}
                    <Box sx={sx.priceContainer}>
                        {product.price && product.price != 0 && product.price != "" && product.price != null ?
                            <Typography variant="title8">${product.price.toLocaleString("en-US")} {product.currency}</Typography>
                        :null}
                        {product.promotion != "" && product.promotion != null ?
                            <Box sx={sx.promoContainer}>
                                <Typography variant="body4" sx={sx.promoText}>{product.promotion}</Typography>
                            </Box>
                        :null}
                    </Box>
                    <Typography variant="body2" sx={sx.locationText}>{product.address && product.address.name != "" ? product.address.name : ""}</Typography>
                </Grid>
            </Grid>
        </ReactVisibilitySensor>
    );
}