import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import sx from './styles'
import imgSuffix from 'common/image-suffix';
import { WhatsApp } from '@mui/icons-material';
import percentageDifference from 'utils/percentageDifference';
import Discount from "assets/svgs/product_insignia/discount.svg"
import FreeShipping from "assets/svgs/product_insignia/free_shipping.svg"
import MSI from "assets/svgs/product_insignia/msi.svg"
import Offer from "assets/svgs/product_insignia/offer.svg"
import { AnalyticsContext } from 'contexts/analyticsContext';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from 'services/firebase/firebaseConfig';

export default function VerticalCard(props) {
    const product = props.product;
    const scopeType = props.scopeType;
    const priceEnabled = product.price && product.price != 0 && product.price != "" && product.price != null;
    const emptyInsignias = (!product.free_shipping && !product.msi && !product.promotion && !product.hasDiscount) ? true : false;
    const [state, dispatch] = React.useContext(AnalyticsContext);
    const navigate = useNavigate()
    const [isInView, setIsInView] = React.useState(false);

    React.useEffect(()=>{

    },[])

    const openProduct = async (referenceUID) => {
        navigate(`/product/${referenceUID}`)
        try{
            let click = {
                productId: referenceUID,
                scopeType: scopeType,
                origin: "organic_web_open"
            }
            const addProductClick = httpsCallable(functions, 'products-addProductClick')
            const result = await addProductClick(click);
            console.log(result.data.message);
        }catch(error){
            console.error('Error logging action:', error.message);
        }
    }

    const onChange = (isVisible) => {
        if(isInView == false && isVisible == true){
            setIsInView(isVisible)
            let uid = product.uid ? product.uid : product.id
            let view = {
                productId: uid,
                scopeType: scopeType,
                origin: "organic_web_open"
            }
            dispatch({
                type: 'SET_PRODUCT_VIEW',
                payload: view
            });
        }
    }

    return (
        <ReactVisibilitySensor onChange={onChange} offset={{left: 50}} minTopValue={80}>
            <Grid container key={product.uid ? product.uid : product.id} onClick={()=>openProduct(product.uid ? product.uid : product.id)} sx={sx.productCard}>
                <Box sx={sx.productImageContainer}>
                    {product.images ?
                        <img
                            alt="img"
                            src={product.images[0] + imgSuffix.md} 
                            height={112} 
                            width={112}
                            style={{objectFit: "cover"}}
                        /> 
                    : (product.legacy_images ? 
                        <img
                            alt="img"
                            src={product.legacy_images[0] + "480x480"} 
                            height={112} 
                            width={112}
                            style={{objectFit: "cover"}}
                        /> 
                    : "")}
                </Box>
                <Grid item sx={sx.productInfoContainer}>
                    <Grid item sx={sx.headText}>
                        <Typography variant="title9" sx={sx.productName}>{product.name}</Typography>
                        <Typography variant="body4" sx={priceEnabled ? sx.productDesc : sx.productDesc2Lines}>{product.description}</Typography>
                    </Grid>
                    {product.hasDiscount && product.hasDiscount == true ?
                        <Grid item sx={sx.previousPriceContainer}>
                            <Typography variant="caption1" sx={sx.previousPriceText}>${product.previousPrice ? product.previousPrice.toLocaleString("en-US") : 0}&nbsp;</Typography>
                        </Grid> 
                    :null}
                    {priceEnabled ?
                        <Grid item sx={sx.priceContainer}>
                            <Typography variant="title8">${product.price.toLocaleString("en-US")}&nbsp;</Typography>
                            {product.discountPercentageVisible && product.hasDiscount && product.hasDiscount == true && product.previousPrice && parseFloat(product.previousPrice) > 0 ? 
                                <Box style={sx.priceBox}>
                                    <Typography variant='body3' style={sx.priceText}>
                                        %{percentageDifference(product.previousPrice, product.price)} OFF
                                    </Typography>
                                </Box>
                            :<></>}
                        </Grid> 
                    :null}
                    <Box style={sx.insigniasContainer}>
                        {product.free_shipping ? <img 
                            src={FreeShipping} 
                            height={18} 
                            width={18} 
                            alt=''
                            style={{marginRight: "4px"}}
                        /> : <></>}
                        {product.msi ? <img 
                            src={MSI} 
                            height={18} 
                            width={18} 
                            alt=''
                            style={{marginRight: "4px"}}
                        /> : <></>}
                        {product.promotion ? <img 
                            src={Offer} 
                            height={18} 
                            width={18} 
                            alt=''
                            style={{marginRight: "4px"}}
                        /> : <></>}
                        {product.discountPercentageVisible && product.hasDiscount && product.hasDiscount == true && product.previousPrice && parseFloat(product.previousPrice) > 0 ? <img 
                            src={Discount} 
                            height={18} 
                            width={18} 
                            alt=''
                            style={{marginRight: "4px"}}
                        /> : <></>}
                        {emptyInsignias ? <WhatsApp
                            color='primary'
                            fontSize="18px"
                        /> : <></>}
                    </Box>
                </Grid>
            </Grid>
        </ReactVisibilitySensor>
    );
}