import { useContext, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Backdrop, Box, Card, Input, InputBase, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import colors from 'theme/colors';
import typography from 'theme/typography';
import { ChevronRightRounded, Search as SearchIcon, Menu as MenuIcon, Close } from '@mui/icons-material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import sx from './treembo-appbar-sx';
import TreemboIcon from 'components/treembo-icon';
import { LandingContext } from 'contexts/landingContext';
import QRCode from 'react-qr-code';
import SearchOverlay from './search_overlay/search_overlay';
import { AnalyticsContext } from 'contexts/analyticsContext';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from 'services/firebase/firebaseConfig';
import { useDebounce } from 'hooks/useDebounce';

const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: "1.5rem",
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginRight: theme.spacing(2),
	marginLeft: 0,
	width: '100%',
	height: "2.5rem",
	[theme.breakpoints.up('md')]: {
		marginLeft: theme.spacing(3),
		width: 'auto',
	},
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: 'inherit',
	fontSize: "16px",
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '22ch',
		},
	},
}));

export default function TreemboAppBar(props) {
	// eslint-disable-next-line no-unused-vars
	const navigate = useNavigate()
  const location = useLocation()
	const [state, dispatch] = useContext(LandingContext);
	const [analytics_state, analytics_dispatch] = useContext(AnalyticsContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const isMobileSM = useMediaQuery(theme.breakpoints.down("sm"));

	const [search_text, set_search_text] = useState("")
  const debounce_search_text = useDebounce(search_text, 300);
	const [search_visible, set_search_visible] = useState(false)
	const [openDrawer, setOpenDrawer] = useState(false)
	const products = useRef([]);
	const stores = useRef([]);
  const [hideSearch, setHideSearch] = useState(true)

  useEffect(()=>{
    console.log('pathname: ', location.pathname)
    setHideSearch(location.pathname === '/searcher')
  },[location.pathname])

	const productViewsData = useRef([]);
	const productViewsInterval = useRef(null);

	const openContact = () =>{
		console.log("OPENING CONTACT: ", state)
		state.openForm()
	}

	// Download backdrop
	const [open, setOpen] = useState(false);
	const handleClose = () => {
		setOpen(false);
	};
	const handleOpen = () => {
		setOpen(true);
	};

	// App downloads
	const handleAppStore = () => {
		window.open("https://apps.apple.com/mx/app/treembo/id1523120507")
		handleClose()
	}
	const handlePlayStore = () => {
		window.open("https://play.google.com/store/apps/details?id=com.treembo")
		handleClose()
	}

	const handleHome = () => {
		window.location.href = '/';
		window.scrollTo(0, 0)
	}

	const clearSearchText = () => {
		set_search_text("")
	}
	
	const handleSearchText = (e) => {
		if(e.target.value.length > 2){
			const analytics = getAnalytics();
			logEvent(analytics, 'search', {
				search_term: e.target.value
			});
		}
		set_search_text(e.target.value)
	}

	const openSearch = () => {
		set_search_visible(true)
	}

	const closeSearch = () => {
		set_search_visible(false)
	}

	const toggleDrawer = (newOpen) => () => {
		setOpenDrawer(newOpen)
	}

	const setProductsResult = (data) => {
		products.current = data
	}

	const setStoresResult = (data) => {
		stores.current = data
	}

	const openSearchView = (event) => {
		event.preventDefault();
		if(search_text.trim().length >= 3){
      closeSearch()
      navigate(`/searcher`, {
        state: {
          searchText: search_text,
          stores: stores.current,
          products: products.current,
        }
      })
    }
	}

	// Escuchar cambios del context y asignarlos a una referencia.
    useEffect(()=>{
        productViewsData.current = analytics_state.productsViews
		// console.log("analytics state check: ", analytics_state.productsViews)
    },[analytics_state.productsViews.length])

    // Se define el intérvalo y se ejecuta la función después del timing seteado.
    useEffect(()=>{
        if(!productViewsInterval.current){
            productViewsInterval.current = setInterval(() => {
                sendProductsViews()
            }, 30000);
        }
        return () => {
            clearInterval(productViewsInterval.current)
        }
    },[])

    // Se leen los ProductViews directamente por la referencia, así no se pierde en el contexto del componente.
    const sendProductsViews = async () => {
        let views = [...productViewsData.current]
        if(views.length > 0){
            // console.log("views: ", views)
			try{
				const addProductViews = httpsCallable(functions, 'products-addProductViews')
				const result = await addProductViews(views);
        		console.log(result.data.message);
				analytics_dispatch({
					type: 'CLEAN_PRODUCT_VIEW',
					payload: []
				});
			}catch(error){
				console.error('Error logging action:', error.message);
			}
        }
    }

	const DrawerList = (
		<Box sx={{ width: 250, marginTop: "8rem", zIndex: 9000 }} role="presentation" onClick={toggleDrawer(false)}>
			<List>
				<ListItem key={"TreeCommerce"}>
					<a href="/" passHref style={{ width: "100%" }}>
						<Button sx={sx.drawerButton}>
							<ListItemText primary={"Tree-Commerce"} sx={{ fontSize: "20px" }}/>
						</Button>
					</a>
				</ListItem>
				<ListItem key={"Blog"}>
					<a href="/news" passHref style={{ width: "100%" }}>
						<Button sx={sx.drawerButton}>
							<ListItemText primary={"Blog"} sx={{ fontSize: "20px" }}/>
						</Button>
					</a>
				</ListItem>
			</List>
		</Box>
	)

	return <>
		<AppBar position="sticky" sx={{height: "8rem", zIndex: 10000}}>
			<Toolbar
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: colors.light_background,
					flexWrap: "wrap",
					height: "4rem"
				}}
			>
				<Typography 
					sx={{
						...(isMobile ? typography.title9 : typography.title6),
						color: colors.twilight,
						marginRight: "0.5rem",
						marginLeft: "0.5rem",
						textAlign: "center",
					}}
				>
					Conoce Triny y su inteligencia artificial como asesor agrícola {"> "}
					<span 
						style={{
							color: colors.primary_green,
							...(isMobile ? typography.title9 : typography.title6),
						}}
					> 
						<a style={{textDecoration: "none", cursor: "pointer"}} onClick={handleOpen}>
							Descarga la App
						</a>
					</span>
				</Typography>
				{/* <ChevronRightRounded
					fontSize={isMobile ? "small" : "large"}
					sx={{
						color: colors.primary_green,
						cursor: "pointer"
					}}
				/> */}
			</Toolbar>
			<Toolbar sx={{ backgroundColor: colors.primary_green, paddingLeft: 0, paddingRight: 0, display: "flex", flexDirection: "row", justifyContent: "space-between", height: "4rem"}}>
				<Box
					sx={{
						display: "flex",
						flex: 1,
						paddingLeft: isMobileSM ? "8px" : "46px",
						flexDirection: "row",
						alignItems: "center",
						cursor: "pointer",
					}}
				>
					<div onClick={handleHome} style={{display: "flex",flexDirection: "row", marginRight: "16px"}}>
						<TreemboIcon icon="treembo" size={25} color={colors.main_white} style={{ marginBottom: "6px" }} />
						{!isMobileSM ?
							<Typography
								variant="h6"
								color="inherit"
								noWrap
								sx={{
									marginLeft: "4.5px",
									color: colors.main_white,
									fontFamily: "Baloo",
								}}
							>
								Treembo
							</Typography>
						:<></>}
					</div>
					{hideSearch ? (
            <div style={{flex: 1}}></div>
          ) : (
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <form className="search_input" onSubmit={openSearchView}>
                <StyledInputBase
                  placeholder="Buscar productos"
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={handleSearchText}
                  value={search_text}
                  onFocus={openSearch}
                  onBlur={closeSearch}
                />
              </form>
            </Search>
          )}
					{isMobile ?
						<div>
							<Box onClick={toggleDrawer(!openDrawer)} style={{ display: "flex", marginRight: isMobileSM ? "8px" : "46px" }}>
								{openDrawer ? 
									<Close sx={{ fontSize: "28px" }}/>
								:
									<MenuIcon sx={{ fontSize: "28px" }}/>
								}
							</Box>
							<Drawer open={openDrawer} anchor={"right"} onClose={toggleDrawer(false)}>
								{DrawerList}
							</Drawer>
						</div>
					:<></>}
				</Box>
				<Box sx={{ display: { xs: 'none', md: 'flex' }, flex: .5}}>
					<a href="/" passHref>
						<Button
							sx={sx.appBarButton}
							color="inherit"
						>
							Tree-Commerce
						</Button>
					</a>
					{/* <a href="/#fresh" passHref>
						<Button
							sx={sx.appBarButton}
							color="inherit"
						>
							Cultivos
						</Button>
					</a>
					<a href="/#connect" passHref>
						<Button
							sx={sx.appBarButton}
							color="inherit"
						>
							Conecta
						</Button>
					</a> */}
					<a href="/news" passHref>
						<Button
							sx={sx.appBarButton}
							color="inherit"
						>
							Blog
						</Button>
					</a>
				</Box>
				{/* <Box sx={{ display: { xs: 'none', laptop: 'flex' }}}>
					<Button 
						onClick={() => openContact()} 
						variant='outlined' 
						size='small' 
						sx={{ marginRight: "16px", borderColor: "white", color: "white"}}
					>
						Contáctanos
					</Button> */}
					{/* <Button 
						onClick={handleOpen} 
						variant='outlined' 
						size='small'
						sx={{ borderColor: "white", color: "white"}}
					>
						Descargar App
					</Button> */}
				{/* </Box> */}
			</Toolbar>
			<Backdrop
				sx={{ color: '#fff' }}
				open={open}
				// onClick={handleClose}
			>
				<Card sx={{padding: "16px", flexDirection: "column", display: "flex"}}>
					<Typography variant='title5' sx={{marginBottom: "16px", fontFamily: "Baloo", color: colors.primary_green, textAlign: "center"}}>
						Descarga la aplicación
					</Typography>
					<QRCode value='https://links.treembo.com/download'/>
					<Box 
						sx={{width: "256px", marginTop: "16px", cursor: "pointer"}}
						onClick={handleAppStore}
					>
						<img 
							src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/2560px-Download_on_the_App_Store_Badge.svg.png"
							width={256}
							alt=''
						/>
					</Box>
					<Box 
						sx={{width: "256px",cursor: "pointer"}}
						onClick={handlePlayStore}
					>
						<img
							src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1024px-Google_Play_Store_badge_EN.svg.png"
							width={256}
							alt=''
						/>
					</Box>
					<Button onClick={handleClose}>
						Regresar
					</Button>
				</Card>
			</Backdrop>
			<SearchOverlay 
				search_visible={search_visible}
				search_text={debounce_search_text}
				clearSearchText={clearSearchText}
				setProductsResult={setProductsResult}
				setStoresResult={setStoresResult}
			/>
		</AppBar>
	</>
}