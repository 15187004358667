import { useState, useEffect, useRef } from 'react';
import { Box, Container, Grid, Typography, CircularProgress, Input } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import constants from 'common/constants';
import imgSuffix from 'common/image-suffix';
import { getAnalytics, logEvent } from 'firebase/analytics'
import colors from "theme/colors";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import TiktokPixel from 'utils/tiktok_pixel';
import HorizontalCard from '../../components/product-horizontal-card';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from 'services/firebase/firebaseConfig';
import { shuffle } from 'lodash';
import { useDebounce } from 'hooks/useDebounce';

const client = algoliasearch(constants.algolia.appId, constants.algolia.searchKey)
const desc_index = client.initIndex('firebase_products_createdAt_desc');
const stores_index = client.initIndex('firebase_stores');

const Searcher = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [products, setProducts] = useState([]);
  const products_ref = useRef([])
  const [loading_products, set_loading_products] = useState(true);
  const [stores, setStores] = useState([]);
  const stores_ref = useRef([])
  const [loading_stores, set_loading_stores] = useState(true);
  const [persist_scroll, set_persist_scroll] = useState(0);
  const [searchText, setSearchText] = useState("");
  const debounce_search_text = useDebounce(searchText, 300);
  const init = useRef(false);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'screen_view', {
      firebase_screen: "ListaBuscador_Web",
      firebase_screen_class: "ListaBuscador_Web"
    });
    TiktokPixel.pageView();

    let storageItem = sessionStorage.getItem("searcher_persist")
    let searcher_persist = JSON.parse(storageItem)
    if (searcher_persist) {
      console.log("persist: ", searcher_persist)
      set_persist_scroll(searcher_persist.scrollY)
      products_ref.current = searcher_persist.products
      set_loading_products(searcher_persist.loading_products)
      stores_ref.current = searcher_persist.stores
      set_loading_stores(searcher_persist.loading_stores)
      setSearchText(searcher_persist.searchText)
      sessionStorage.removeItem("searcher_persist")
    } else if (location.state && (location.state.stores || location.state.products)) {
      setSearchText(location.state.searchText)
      if (location.state.products && location.state.products.length) {
        console.log("products location state: ", location.state.products)
        products_ref.current = location.state.products
        set_loading_products(false)
      }
      if (location.state.stores && location.state.stores.length) {
        console.log("stores location state: ", location.state.stores)
        stores_ref.current = location.state.stores
        set_loading_stores(false)
      }
    } else {
      console.log("vacío")
      window.scrollTo(0, 0)
      getProducts()
      getStores()
    }
    setTimeout(() => {
      init.current = true
    }, 2000);
  }, [])

  useEffect(() => {
    if (init.current) {
      if (persist_scroll && products.length > 0) {
        window.scrollTo(0, persist_scroll)
        set_persist_scroll(0)
      }
    }
  }, [products, persist_scroll, init.current])

  const getProducts = async () => {
    try {
      setProducts([])
      let filters = `ownerType:"store"`
      const { hits } = await desc_index.search(searchText, {
        filters: filters,
        ruleContexts: "client"
      });
      console.log("Product hits: ", hits)
      let auxProducts = []
      hits.forEach((doc) => {
        auxProducts.push({
          ...doc,
          uid: doc.objectID,
          price: parseFloat(doc.price),
        })
      });
      set_loading_products(false)
      let result = [...auxProducts]
      if (searchText) {
        result = [...shuffle(result)]
      }
      products_ref.current = result
    } catch (error) {
      console.log("GET PRODUCTS ERROR: ", error)
      set_loading_products(false)
    }
  }

  useEffect(() => {
    setProducts(products_ref.current)
  }, [products_ref.current])

  const getStores = async () => {
    try {
      setStores([])
      let filters = ``
      stores_index.search(searchText, {
        filters: filters,
        ruleContexts: "client"
      }).then(({ hits }) => {
        // console.log("Product hits: ", hits)
        let auxStores = []
        hits.forEach((doc) => {
          auxStores.push({
            ...doc,
            uid: doc.objectID,
          })
        });
        set_loading_stores(false)
        let result = [...auxStores]
        if (searchText) {
          result = [...shuffle(result)]
        }
        stores_ref.current = result
      });
    } catch (error) {
      console.log("GET STORES ERROR: ", error)
      set_loading_stores(false)
    }
  }

  useEffect(() => {
    setStores(stores_ref.current)
  }, [stores_ref.current])

  const handleSubmit = (e) => {
    setSearchText(e.target.value)
  }

  const handleSearch = () => {
    console.log('handle search')
    set_persist_scroll(0)
    set_loading_products(true)
    set_loading_stores(true)
    getProducts()
    getStores()
  }

  useEffect(() => {
    if (init.current && (debounce_search_text.length > 2 || debounce_search_text.length == 0)) {
      if (debounce_search_text.length > 2) {
        const analytics = getAnalytics();
        logEvent(analytics, 'search', {
          search_term: debounce_search_text
        });
      }
      handleSearch()
    }
  }, [debounce_search_text])

  const openProduct = async (product) => {
    let searcher_persist = {
      products,
      stores,
      loading_products,
      loading_stores,
      scrollY: window.scrollY,
      searchText
    }
    sessionStorage.setItem("searcher_persist", JSON.stringify(searcher_persist))
    let uid = ""
    if (product.objectID) {
      uid = product.objectID
    } else if (product.uid) {
      uid = product.uid
    } else {
      uid = product.id
    }
    navigate(`/product/${uid}`)
    try {
      let click = {
        productId: uid,
        scopeType: "search",
        origin: "organic_web_open"
      }
      const addProductClick = httpsCallable(functions, 'products-addProductClick')
      const result = await addProductClick(click);
      console.log(result.data.message);
    } catch (error) {
      console.error('Error logging action:', error.message);
    }
  }

  const openStore = async (store) => {
    if (store.username) {
      let searcher_persist = {
        products,
        stores,
        loading_products,
        loading_stores,
        scrollY: window.scrollY,
        searchText
      }
      sessionStorage.setItem("searcher_persist", JSON.stringify(searcher_persist))
      navigate(`/store/${store.username}`)
    }
  }

  const _renderProducts = (data) => {
    if (data.length) {
      return data.map((product, index) => (
        <div key={index}>
          <HorizontalCard
            product={product}
            openProduct={() => openProduct(product)}
            scopeType={"search"}
          />
          <div style={{ backgroundColor: colors.light_gray, height: "1px", width: "100%" }} />
        </div>
      ))
    } else {
      return <div style={{ width: "100%", height: "5rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography sx={{ color: "black" }}>
          No hubo resultados de productos
        </Typography>
      </div>
    }
  }

  const _renderStores = () => {
    if (stores.length) {
      return stores.map((store, index) => (
        <div onClick={() => openStore(store)} key={`store-algolia-${index}`} style={{ height: "8rem", width: "5.2rem", minWidth: "5.2rem", display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "0.5rem", cursor: "pointer" }}>
          <div style={{ height: "4rem", width: "4rem", borderRadius: "2rem", overflow: "hidden", backgroundColor: store.backgroundColor ? "#" + store.backgroundColor : "#1FADC1" }}>
            <img
              alt="img"
              src={store.logo + imgSuffix.md}
              style={{ display: "flex", width: "4rem", objectFit: "cover", height: "4rem", aspectRatio: "1:1" }}
            />
          </div>
          <div style={{ width: "100%", paddingTop: "0.2rem" }}>
            <Typography sx={{ fontSize: "12px", color: "black", textAlign: "center" }}>{store.name}</Typography>
          </div>
        </div>
      ))
    } else {
      return <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography sx={{ color: "black" }}>
          No hubo resultados de tiendas
        </Typography>
      </div>
    }
  }

  return (
    <Container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", paddingBottom: "40px", alignItems: "center" }}>
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%", top: "8rem", backgroundColor: "white", zIndex: 5000, paddingTop: "16px", alignItems: "center" }}>
        <ChevronLeft sx={{ fontSize: 36, marginRight: "16px" }} color="primary" onClick={() => { navigate(-1) }} />
        <Typography variant='title4'>
          Buscador
        </Typography>
      </Box>
      <Input
        placeholder={"Buscar..."}
        sx={{ width: "100%", height: "32px", marginTop: "16px", paddingLeft: "16px", paddingRight: "16px", border: "1px solid " + colors.medium_light_gray, borderRadius: "16px" }}
        disableUnderline={true}
        onChange={handleSubmit}
        value={searchText}
        autoFocus={true}
      />
      {!stores.length && !products.length && !loading_products && !loading_stores ?
        <Box minHeight={250} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Typography sx={{ color: "black" }}>
            La búsqueda no obtuvo resultados, revisa y ajusta tu consulta.
          </Typography>
        </Box>
        : <></>}
      {stores.length ?
        <Grid container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", marginTop: "16px" }}>
          <Typography style={{ fontSize: "16px", marginLeft: "1rem", fontWeight: "bold", color: "black", marginBottom: "0.5rem", marginTop: "1rem" }}>
            Tiendas
          </Typography>
          {loading_stores ?
            <CircularProgress style={{ color: colors.primary_green }} />
            :
            <Grid container sx={{ display: 'flex', flexDirection: "column", overflow: "auto", backgroundColor: colors.light_background }}>
              <Grid sx={{ display: 'flex', flexDirection: "row", marginTop: "8px" }}>
                {_renderStores()}
              </Grid>
            </Grid>
          }
        </Grid>
        : <></>}
      {products.length ?
        <Grid container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "center", marginTop: "16px", backgroundColor: "white" }}>
          <Typography style={{ fontSize: "16px", marginLeft: "1rem", fontWeight: "bold", color: "black", marginBottom: "0.5rem", marginTop: "0.5rem" }}>
            Productos
          </Typography>
          {loading_products ?
            <CircularProgress style={{ color: colors.primary_green }} />
            :
            _renderProducts(products)
          }
        </Grid>
        : <></>}
    </Container>
  )
}

export default Searcher;