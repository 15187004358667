import colors from 'theme/colors';

let sx = {
	container: {
        paddingLeft: "1rem", 
        paddingRight: "1rem", 
        paddingBottom: "1rem", 
        display: "flex", 
        flexDirection: "row", 
        color: "black", 
        cursor: "pointer", 
        alignItems: "center"
    },
    imageContainer: {
        height: "4.5rem", 
        width: "4.5rem", 
        backgroundColor: colors.light_background, 
        borderRadius: "0.5rem", 
        overflow: "hidden"
    },
    image: {
        display: "flex", 
        width: "100%", 
        objectFit: "cover", 
        height: "100%", 
        aspectRatio: "1:1"
    },
    infoContainer: {
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "center", 
        flex: 1, 
        paddingLeft: "1rem", 
        paddingRight: "1rem"
    },
    nameText: { 
        display: '-webkit-box', 
        overflow: 'hidden', 
        WebkitBoxOrient: 'vertical', 
        WebkitLineClamp: 1 
    },
    descriptionText: { 
        display: '-webkit-box', 
        overflow: 'hidden', 
        WebkitBoxOrient: 'vertical', 
        WebkitLineClamp: 2 
    },
    previousPriceContainer: { 
        display: "flex", 
        flexDirection: "row", 
        alignItems: "flex-end" 
    },
    previousPriceText: { 
        color: colors.medium_gray, 
        textDecorationLine: "line-through" 
    },
    priceContainer: { 
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "flex-start", 
        alignItems: "center" 
    },
    percentageContainer: {
        display: "flex", 
        flexDirection: "row", 
        alignItems: "center", 
        marginLeft: "8px"
    },
    percentageText: {
        color: colors.primary_green, 
        fontSize: "12px"
    },
    discount: {
        marginLeft: "8px"
    },
    insigniasContainer: {
        display: "flex", 
        flexDirection: "row", 
        flexWrap: "wrap"
    },
    insignia: {
        marginRight: "4px"
    }
}

export default sx;