import colors from 'theme/colors';

let sx = {
	container: { 
        display: "flex", 
        flexDirection: "row",
        backgroundColor: "white", 
        minHeight: "100px", 
        marginTop: "4px", 
        justifyContent: "center", 
        alignItems: "center", 
        cursor: "pointer", 
        marginBottom: "4px" 
    },
    imageContainer: { 
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "center", 
        alignItems: "center", 
        cursor: "pointer", 
        borderRadius: "8px", 
        overflow: "hidden", 
        position: "relative" 
    },
    image: {
        display: "flex",
        width: "100%", 
        objectFit: "fill", 
        height: "5.4rem", 
        aspectRatio: "1:1"
    },
    infoContainer: { 
        marginLeft: "16px", 
        cursor: "pointer" 
    },
    nameText: { 
        display: '-webkit-box', 
        overflow: 'hidden', 
        WebkitBoxOrient: 'vertical', 
        WebkitLineClamp: 1 
    },
    descriptionText: { 
        display: '-webkit-box', 
        overflow: 'hidden', 
        WebkitBoxOrient: 'vertical', 
        WebkitLineClamp: 2 
    },
    previousPriceContainer: { 
        display: "flex", 
        flexDirection: "row", 
        alignItems: "flex-end" 
    },
    previousPriceText: { 
        color: colors.medium_gray, 
        textDecorationLine: "line-through" 
    },
    priceContainer: { 
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "flex-start", 
        alignItems: "center" 
    },
    promoContainer: {
        display: "flex", 
        backgroundColor: "sunset", 
        marginLeft: "8px", 
        height: "20px", 
        borderRadius: "12px", 
        paddingLeft: "8px", 
        paddingRight: "8px", 
        alignItems: "center"
    },
    promoText: {
        display: '-webkit-box', 
        overflow: 'hidden', 
        WebkitBoxOrient: 'vertical', 
        WebkitLineClamp: 1, 
        color: "white", 
        marginTop: "2px" 
    },
    locationText: { 
        color: "gray", 
        display: '-webkit-box', 
        overflow: 'hidden', 
        WebkitBoxOrient: 'vertical', 
        WebkitLineClamp: 1 
    },
}

export default sx;