import React, { useEffect } from 'react';

import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { CssBaseline, ThemeProvider } from '@mui/material';
import TreemboTheme from 'theme/treemboTheme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LandingProvider } from 'contexts/landingContext';
import { AnalyticsProvider } from 'contexts/analyticsContext';

import BannersAppbar from 'routes/landingBanners/banner-components/banners-appbar/banners-appbar';
import DeleteAccount from 'routes/delete_account';
import ErrorPage from 'routes/error/error';
import Home from 'routes/home/home.screen';
import LandingBanners from 'routes/landingBanners/landingBanners';
import LoadingPage from 'routes/loading/loading';
import News from 'routes/news';
import NewScreen from 'routes/news/new/new';
import PaymentSuccess from 'routes/success';
import PrivacyTerms from 'routes/privacy_terms';
import Products from 'routes/products';
import ProductScreen from 'routes/products/product/product';
import Searcher from 'routes/searcher';
import Stores from 'routes/stores';
import StoreScreen from 'routes/stores/store/store';
import StoreProductsScreen from 'routes/stores/store/products';
import TermsOfUse from 'routes/terms_of_use';
import TreemboAppBar from 'components/appbar/treembo-appbar';
import TiktokPixel from 'utils/tiktok_pixel';
import constants from 'common/constants';
import Maintenance from 'maintenance';

const getCurrentURL = () => {
  console.log("current url: ", window.location.href)
  return window.location.href
}

const HeaderLayout = () => (
  <>
    <header>
      {
        String(getCurrentURL()).includes("landingBanners") ? (
          <BannersAppbar/>
        ) : (
          String(getCurrentURL()).includes("terms") && String(getCurrentURL()).includes("mobile=true") ? (
            null
          ) : (
            <TreemboAppBar/>
          )
        )
      }
    </header>
    <Outlet />
  </>
);


const router = createBrowserRouter([
  {
    element: <HeaderLayout/>,
    children: [
      {
        path: "/",
        element: <Home/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "products",
        element: <Products/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "product/:productId",
        element: <ProductScreen />,
        errorElement: <ErrorPage/>
      },
      {
        path: "searcher",
        element: <Searcher/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "stores",
        element: <Stores/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "store/:username",
        element: <StoreScreen/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "store/:username/products",
        element: <StoreProductsScreen/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "news",
        element: <News/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "new/:newId",
        element: <NewScreen/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "terms_of_use",
        element: <TermsOfUse/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "usage",
        element: <TermsOfUse/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "privacy_terms",
        element: <PrivacyTerms/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "privacy",
        element: <PrivacyTerms/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "account_deletion",
        element: <DeleteAccount/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "landingBanners",
        element: <LandingBanners/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "success",
        element: <PaymentSuccess/>,
        errorElement: <ErrorPage/>
      },
      {
        path: "loading",
        element: <LoadingPage/>,
        errorElement: <ErrorPage/>
      },
    ]
  }
]);

const App = () => {
    

    const init = () => {
        TiktokPixel.init('CPK7T5BC77UFVACF4RQG');
    }

    useEffect(()=>{
        init()
    },[])

    if(constants.maintenance){
      return <Maintenance/>
    }

    return (
        <LandingProvider>
          <AnalyticsProvider>
            <ThemeProvider theme={TreemboTheme}>
            <CssBaseline/>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <RouterProvider router={router} />
            </LocalizationProvider>
            </ThemeProvider>
          </AnalyticsProvider>
        </LandingProvider>
    )
}

export default App;
